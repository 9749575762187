import { ComparisonDashboard as CD } from '@aitechnologies-it/va-backoffice-dashboard';
import { useAthentication, useRecord } from "@gemini-projects/gemini-react-admin-app";
import { Alert, Progress, Row } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

const DASHBOARD_DATA_URI = process.env.REACT_APP_COMPARISON_DATA_API

const ClusteringComparison = (props: any) => {
    const { id } = useParams<any>();

    const request_data_uri = DASHBOARD_DATA_URI + "/" + id;


    const [data, setData] = useState<any>(undefined)
    const [error, setError] = useState<boolean>(false)
    const [comparisonData, setComparisonData] = useState<any>(undefined);

    const [loadingState, setLoadingState] = useState<any>(0)
    const { isLoading, isLogged, accessToken } = useAthentication(props.authentication);


    useEffect(() => {
        async function data() {
            let httpConfig = {
                headers: { 'Authorization': 'Bearer ' + accessToken },
            }
            try {
                const resp = await Axios.get(request_data_uri, httpConfig);
                const gsHttpConfig = {
                    onDownloadProgress: (progressEvent: any) => {
                        if (progressEvent.total) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setLoadingState(percentCompleted)
                        }
                    }
                }
                const googleResp = await Axios.get(resp.data, gsHttpConfig);
                setTimeout(() => setData(googleResp.data), 100)


            } catch (e) {
                setError(true)
            }
        }


        if (isLogged) {
            data();
        }
    }, [isLogged])

    return data ? <div style={{ minHeight: '100vh' }}><CD data={data}/></div> : (
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
            {error ? <Alert
                message={`Error getting the experiment ${id}`}
                showIcon
                description=""
                type="error"
            /> :    //<Spin size="large" />
                <Progress type="circle" percent={loadingState} />
            }
        </Row>

    )

}

export default ClusteringComparison
